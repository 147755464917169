import React from 'react'

import Index from 'src/layouts'
import Seo from 'src/components/Seo'

const NotFoundPage = () => (
  //Fixed on netlify as per: https://github.com/gatsbyjs/gatsby/issues/5329#issuecomment-484741119
  <Index>
    {typeof window !== 'undefined' && (
      <>
        {' '}
        <Seo title="404: Not found" />
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </>
    )}
  </Index>
)

export default NotFoundPage
